import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@material-tailwind/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { flowbiteTheme, materialTheme } from "constants/theme";
import { Flowbite } from "flowbite-react";
import "./css/index.css";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_ID
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Flowbite theme={{ theme: flowbiteTheme }}>
      <ThemeProvider value={materialTheme}>
        <App />
      </ThemeProvider>
    </Flowbite>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
