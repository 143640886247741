export const OPTIONS_GENDER = [
  { key: 0, label: "Tidak diketahui" },
  { key: 1, label: "Laki-laki" },
  { key: 2, label: "Perempuan" },
  { key: 3, label: "Tidak dapat ditentukan" },
]

export const OPTIONS_GENDER_USER = [
  { key: 1, label: "Laki-laki" },
  { key: 2, label: "Perempuan" },
]

export const OPTIONS_RELIGION = [
  { key: 1, label: "Islam" },
  { key: 2, label: "Kristen" },
  { key: 3, label: "Katolik" },
  { key: 4, label: "Hindu" },
  { key: 5, label: "Budha" },
  { key: 6, label: "Khonghucu" },
  { key: 7, label: "Penghayat" },
  { key: 8, label: "Lain-lain" },
]

export const OPTIONS_EDUCATION = [
  { key: 0, label: "Tidak Sekolah" },
  { key: 1, label: "SD" },
  { key: 2, label: "SLTP/Sederajat" },
  { key: 3, label: "SLTA/Sederajat" },
  { key: 4, label: "D1-D3/Sederajat" },
  { key: 5, label: "S1" },
  { key: 6, label: "S2" },
  { key: 7, label: "S3" },
]

export const OPTIONS_OCCUPATION = [
  { key: 0, label: "Tidak bekerja" },
  { key: 1, label: "PNS" },
  { key: 2, label: "TNI/Polri" },
  { key: 3, label: "BUMN" },
  { key: 4, label: "Pegawai Swasta/Wiraswasta" },
  { key: 5, label: "Lain-lain" },
]

export const OPTIONS_MARITAL_STATUS = [
  { key: 1, label: "Belum kawin" },
  { key: 2, label: "Kawin" },
  { key: 3, label: "Cerai hidup" },
  { key: 4, label: "Cerai mati" },
]

export const OPTIONS_PAYMENT_METHOD = [
  { key: 1, label: "Mandiri" },
  { key: 2, label: "BPJS" },
  { key: 3, label: "Asuransi Lainnya" },
]

export const OPTIONS_PRESCRIPTION_TIMING = [
  { key: '1', label: 'Pagi' },
  { key: '2', label: 'Siang' },
  { key: '3', label: 'Sore' },
  { key: 'other', label: 'Lain-lain' },
]

export const OPTIONS_PRESCRIPTION_SPECIFIC_TIME = [
  { key: '1', label: 'Sebelum makan' },
  { key: '2', label: 'Sesudah makan' },
  { key: '3', label: 'Bersamaan makan' },
  { key: 'other', label: 'Lain-lain' },
]

export const OPTIONS_SENSES_LEVEL = [
  { key: 1, label: "Sadar Baik / Alert" },
  { key: 2, label: "Verbal" },
  { key: 3, label: "Pain" },
  { key: 4, label: "Unresponsive" },
  { key: 5, label: "Apatis" },
]

export const OPTIONS_PHYSCOLOGYCAL_STATUS = [
  { key: 1, label: "Tidak ada kelainan" },
  { key: 2, label: "Cemas" },
  { key: 3, label: "Marah" },
  { key: 4, label: "Sedih" },
  { key: 5, label: "Lain-lain" },
]

export const OPTIONS_DIAGNOSE = [
  { key: 1, label: "Diagnosis Utama / Primer" },
  { key: 2, label: "Diagnosis Tambahan / Sekunder" },
];

export const OPTIONS_ROUTE = [
  { key: 1, label: "Injeksi" },
  { key: 2, label: "Oral" },
  { key: 3, label: "Suppositoria" },
  { key: 4, label: "Topikal" },
  { key: 5, label: "Sublingual" },
  { key: 6, label: "Inhalasi" },
];

export const OPTIONS_PATIENT_STATUS = [
  { key: 1, label: "Pulang" },
  { key: 2, label: "Rujuk Rawat Jalan" },
  { key: 3, label: "Rujuk Rawat Inap" },
  { key: 4, label: "Meninggal" },
];

export const OPTIONS_CATEGORY = [
  { key: 1, label: 'Obat' },
  { key: 3, label: 'BHP' },
  { key: 5, label: 'Kosmetika' },
  { key: 99, label: 'Lain-lain' }
];

export const OPTIONS_TRANSACTION_SOURCE = [
  { key: 1, label: 'Input Stok' },
  { key: 2, label: 'Pembelian' },
  { key: 3, label: 'Peresepan' },
]

export const OPTIONS_MONTH = [
  { key: 1, label: 'Januari' },
  { key: 2, label: 'Februari' },
  { key: 3, label: 'Maret' },
  { key: 4, label: 'April' },
  { key: 5, label: 'Mei' },
  { key: 6, label: 'Juni' },
  { key: 7, label: 'Juli' },
  { key: 8, label: 'Agustus' },
  { key: 9, label: 'September' },
  { key: 10, label: 'Oktober' },
  { key: 11, label: 'November' },
  { key: 12, label: 'Desember' },
]

export const OPTIONS_POLYCLINIC_UNIT = [
  { key: 1, label: 'Poli Umum' },
  { key: 2, label: 'Poli Gigi' }
]

export const OPTIONS_QUEUE_PRIORITY = [
  { key: 1, label: '0' },
  { key: 2, label: '1' },
  { key: 3, label: '2' },
]

export const OPTIONS_CONSCIOUS_CONDITION = [
  { key: 0, label: 'Sadar Penuh' },
  { key: 1, label: 'Tampak Mengantuk / Gelisah Bicara Tidak Jelas' },
  { key: 2, label: 'Tidak Sadar' },
]

export const OPTIONS_RESPIRATORY_CONDITION = [
  { key: 0, label: 'Nafas Normal' },
  { key: 1, label: 'Tampak Sesak' },
  { key: 2, label: 'Tidak Bernafas' },
]

export const OPTIONS_RISK_OF_FALLING = [
  { key: 0, label: 'Risiko Rendah' },
  { key: 1, label: 'Risiko Sedang' },
  { key: 2, label: 'Risiko Tinggi' },
]

export const OPTIONS_PAINFUL = [
  { key: 0, label: 'Skor 1-3 (Nyeri Ringan)' },
  { key: 1, label: 'Skor 4-7 (Nyeri Sedang)' },
  { key: 2, label: 'Skor 8-10 (Nyeri Berat)' },
]

export const OPTIONS_COUGH = [
  { key: 0, label: 'Tidak Ada / <2 Minggu' },
  { key: 1, label: '>= 2 Minggu' },
]

export const OPTIONS_USER_TYPE = [
  { key: 2, label: 'Asisten Dokter' },
  { key: 3, label: 'Dokter' },
  { key: 6, label: 'Apoteker' },
  { key: 8, label: 'Kasir' },
]

export const OPTIONS_PHARMACY_STATUS = [
  { key: 1, label: 'Belum Dilayani' },
  { key: 2, label: 'Sedang Disiapkan' },
  { key: 3, label: 'Obat Sudah Siap' },
  { key: 4, label: 'Obat Diserahkan' },
]

export const OPTIONS_REFERRAL_TYPE = [
  { key: 1, label: 'Rujuk Internal' },
  { key: 2, label: 'Rujuk Eksternal' },
]

export const OPTIONS_SPESIALIST_TYPE = [
  { key: 1, label: 'Spesialis' },
  { key: 2, label: 'Kondisi Khusus' },
]

export const OPTIONS_TACC = [
  { key: "-1", label: 'Tanpa TACC' },
  { key: "1", label: 'Time' },
  { key: "2", label: 'Age' },
  { key: "3", label: 'Complication' },
  { key: "4", label: 'Comorbidity' },
]

export const OPTIONS_EXAMINATION_STATUS = [
  { key: "1", label: 'Berobat Jalan' },
  { key: "2", label: 'Rujuk Vertikal' },
  { key: "3", label: 'Rujuk Horizontal' },
  { key: "4", label: 'Meninggal' },
  { key: "5", label: 'Rujuk Atas Permintaan Sendiri (APS)' },
  { key: "6", label: 'Sembuh' },
  { key: "7", label: 'Meninggal' },
  { key: "8", label: 'Pulang Paksa' },
  { key: "9", label: 'Rujuk Vertikal' },
  { key: "10", label: 'Rujuk Horizontal' },
  { key: "11", label: 'Lain-lain' },
  { key: "12", label: 'Rujuk Atas Permintaan Sendiri (APS)' },
]