import { Button, CircularProgress, TextField, MenuItem } from "@mui/material";
import { useEditUser, useUserDetail } from "api/user";
import FormSection from "components/FormSection";
import PageLoader from "components/PageLoader";
import { OPTIONS_GENDER_USER } from "constants/options";
import useToaster from "context/ToasterContext";
import { UserData } from "entities/model/user";
import { useEffect } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { Controller, useForm } from "react-hook-form";
import { UserDetailResponse } from "types/user-detail";

type UserType = Partial<UserDetailResponse["data"]["user"]>;

const FormUser = () => {
  const auth = useAuthUser<UserData>();
  const toaster = useToaster();

  const user_id = auth?.userId;
  const { data } = useUserDetail({ user_id });
  const editUser = useEditUser();
  const userData = data?.data?.data?.user;

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm<UserType>();

  const watchGender = watch('gender');

  useEffect(() => {
    if (userData) {
      setValue("full_name", userData.full_name);
      setValue("email", userData.email);
      setValue("phone_number", userData.phone_number);
      setValue("id_card_number", userData.id_card_number);
      setValue("license_number", userData.license_number);
      setValue("gender", userData.gender || "1");
      setValue("birth_date", userData.birth_date);
      setValue("address", userData.address);
      setValue("service_fee", userData.service_fee || "0");
    }
  }, [setValue, userData]);

  const submitForm = (val: UserType) => {
    if (!editUser.isLoading) {
      editUser
        .mutateAsync({ user_id, ...val })
        .then((response) => {
          const success = response?.data?.success === true;
          toaster.open({
            title: success ? "Berhasil" : "Gagal",
            message: success
              ? "Berhasil menyimpan perubahan"
              : response?.data.message,
            variant: success ? "success" : "error",
            autoClose: true,
          });
        })
        .catch((err) => {
          toaster.open({
            title: "Gagal",
            message: err,
            variant: "error",
            autoClose: true,
          });
        });
    }
  };

  return (
    <>
      {editUser.isLoading && <PageLoader />}
      <form onSubmit={handleSubmit(submitForm)}>
        <FormSection title="Profil" className="max-w-xl pt-2">
          <div className="grid grid-cols-4 gap-6">
            <div className="col-span-4">
              <TextField
                label="Nama Lengkap"
                fullWidth
                required
                {...register("full_name", { required: "Wajib diisi" })}
                error={Boolean(errors.full_name)}
                helperText={errors.full_name?.message || "Nama lengkap beserta gelar"}
              />
            </div>
            <div className="col-span-4">
              <TextField
                label="Email"
                fullWidth
                required
                {...register("email", {
                  required: "Email tidak boleh kosong",
                  validate: (value) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) || "Email tidak valid",
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </div>
            <div className="col-span-4">
              <TextField
                label="Nomor Telepon"
                fullWidth
                required
                type="number"
                {...register("phone_number", { required: "Wajib diisi" })}
                error={Boolean(errors.phone_number)}
                helperText={errors.phone_number?.message}
              />
            </div>
            <div className="col-span-4">
              <TextField
                label="Nomor Induk Kependudukan (NIK)"
                fullWidth
                required
                type="number"
                disabled={Boolean(userData?.ihs_id)}
                {...register("id_card_number", { required: "Wajib diisi" })}
                error={Boolean(errors.id_card_number)}
                helperText={errors.id_card_number?.message}
              />
            </div>
            <div className="col-span-4">
              <TextField
                required
                label="Nomor Surat Izin Praktik (SIP)"
                fullWidth
                {...register("license_number", { required: "Wajib diisi" })}
                error={Boolean(errors.license_number)}
                helperText={errors.license_number?.message}
              />
            </div>
            <div className="col-span-4">
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Wajib diisi",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    required
                    defaultValue={watchGender}
                    label="Jenis Kelamin"
                    error={Boolean(errors?.gender)}
                    helperText={errors?.gender?.message}
                    value={field.value ?? ""}
                  >
                    {OPTIONS_GENDER_USER.map((item) => (
                      <MenuItem key={item.key} value={String(item.key)}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </div>
            <div className="col-span-4">
              <TextField
                label="Tanggal Lahir"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                {...register("birth_date", { required: "Wajib diisi" })}
                error={Boolean(errors.birth_date)}
                helperText={errors.birth_date?.message}
              />
            </div>
            <div className="col-span-4">
              <TextField
                label="Alamat"
                fullWidth
                required
                multiline
                rows={3}
                {...register("address", { required: "Wajib diisi" })}
                error={Boolean(errors.address)}
                helperText={errors.address?.message}
              />
            </div>
            <div className="col-span-4">
              <TextField
                label="Biaya Jasa Konsultasi Dokter"
                fullWidth
                required
                {...register("service_fee", { required: "Wajib diisi" })}
                error={Boolean(errors.service_fee)}
                helperText={errors.service_fee?.message}
              />
            </div>
            <div className="col-span-4 flex justify-end">
              <Button type="submit" variant="contained" disabled={editUser.isLoading}>
                {editUser.isLoading ? <CircularProgress size={24} /> : "Simpan"}
              </Button>
            </div>
          </div>
        </FormSection>
      </form>
    </>
  );
};

export default FormUser;
