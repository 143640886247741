import { Button, Card, Collapse, List, ListItem, Tooltip, Typography } from "@material-tailwind/react";
import ModalChangePassword from "components/organism/ModalChangePassword";
import ModalStockRebalancing from "components/organism/ModalRebalancing";
import SearchPasien from "components/organism/SearchPasien";
import useAppContext from "context/AppContext";
import useOutsideAlert from "hooks/useOutsideAlert";
import { useRef, useState } from "react";
import { FaSync } from "react-icons/fa";
import { HiMenu, HiUserCircle } from "react-icons/hi";
import { TbPlus } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";

function Header({ toggleSidebar }) {
  const [showPasienModal, setShowPasienModal] = useState(false);
  const [showRebalancing, setShowRebalancing] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showChangePasswordModal, setshowChangePasswordModal] = useState(false);

  const { userDetail, clinicDetail } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const isHome = location.pathname === '/';

  const userRef = useRef(null);
  useOutsideAlert(userRef, () => {
    setShowMenu(false);
  })

  return (
    <>
      <header className="relative flex-shrink-0 border-b">
        <div className="flex items-center justify-between p-2">
          {/* Navbar left */}
          <div className="flex items-center space-x-3">
            {/* Toggle sidebar button */}
            <button
              onClick={toggleSidebar}
              className="block rounded-md p-2 lg:hidden"
            >
              <HiMenu className="text-2xl text-gray-600 " />
            </button>
          </div>

          {/* Navbar right */}
          <div className="flex justify-end items-center gap-2 divide-x" ref={userRef}>
            {isHome && (
              <div className="flex justify-end items-center gap-2">
                <Tooltip placement="bottom" content="Pembaruan Data">
                  <Button
                    size="sm"
                    variant="outlined"
                    color="gray"
                    onClick={() => setShowRebalancing(true)}
                  >
                    <FaSync />
                  </Button>
                </Tooltip>
                <Button
                  size="sm"
                  type="button"
                  variant="filled"
                  onClick={() => setShowPasienModal(true)}
                >
                  <div className="flex items-center gap-2">
                    <TbPlus />
                    Kunjungan Baru
                  </div>
                </Button>
              </div>
            )}

            {/* avatar button */}
            <div className="pl-1">
              <div className="relative flex items-center gap-2 cursor-pointer active:bg-gray-200 hover:bg-gray-200 py-1 px-2 rounded-lg" onClick={() => setShowMenu(!showMenu)}>
                <div className="flex items-center gap-2 rounded-[40px] bg-gray-100 p-0.5 ">
                  <HiUserCircle className="w-[32px] h-[32px] text-gray-700" />
                  <Collapse open={showMenu} className="absolute top-[100%] w-[300px] right-0 z-50 mt-2 shadow-md">
                    <Card className="border">
                      <List>
                        <ListItem onClick={() => navigate('/settings')} className="border-b">
                          <Typography className="font-semibold">{userDetail?.full_name || ''}</Typography>
                        </ListItem>
                        <ListItem onClick={() => navigate('/settings')}>Pengaturan</ListItem>
                        <ListItem onClick={() => setshowChangePasswordModal(true)}>Ganti Password</ListItem>
                        <ListItem onClick={() => navigate('/logout')}>Logout</ListItem>
                      </List>
                    </Card>
                  </Collapse>
                </div>
                <div className="hidden lg:block">
                  <Typography variant="h6" className="font-bold leading-tight">{userDetail?.full_name || ''}</Typography>
                  <Typography variant="small" className="text-xs text-gray-600 leading-tight">{clinicDetail?.clinic_name || ''}</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>


      <SearchPasien
        source="medical-record"
        open={showPasienModal}
        onClose={() => setShowPasienModal(false)}
      />
      <ModalStockRebalancing
        open={showRebalancing}
        onClose={() => setShowRebalancing(false)}
      />
      <ModalChangePassword
        open={showChangePasswordModal}
        onClose={() => setshowChangePasswordModal(false)}
      />
    </>
  );
}

export default Header;
