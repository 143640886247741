import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const ForbiddenPage = () => {
  return (
    <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
      <div className="mx-auto max-w-screen-sm space-y-4 text-center">
        <img
          src="/img_warning.svg"
          alt="Not Found"
          className="mx-auto mb-8 max-w-sm"
        />
        <Typography variant="h4">Maaf..</Typography>
        <Typography variant="body1">
          Anda tidak memiliki hak akses untuk melihat halaman ini.
        </Typography>
        <Link to="/" className="mx-auto inline-block">
          <Button variant="contained">Kembali ke Halaman Utama</Button>
        </Link>
      </div>
    </div>
  );
};

export default ForbiddenPage;
