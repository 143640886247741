import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageView',
                page: {
                    url: window.location.href,
                    title: document.title,
                },
            },
        });
    }, [location]);
};

export default usePageTracking;
