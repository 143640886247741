import { useState } from 'react';
import { Label, Select } from 'flowbite-react';
import { Button } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { useAllMenu } from 'api/menu';
import { OPTIONS_USER_TYPE } from 'constants/options';
import { AccessRoleInsertType } from 'types/access-role-create';
import * as IconsTb from "react-icons/tb";
import * as IconsGi from "react-icons/gi";
import { CircularProgress } from '@mui/material';

interface CreateUserProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (user: AccessRoleInsertType) => void;
}

const ModalAddAccessRoleUser = ({ isOpen, onClose, onSave }: CreateUserProps) => {
    const { data: allMenusData, isFetching: isFetchingAllMenus } = useAllMenu();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<AccessRoleInsertType>();

    const [selectedMenus, setSelectedMenus] = useState<number[]>([]);

    const handleAddNewUser = (val: AccessRoleInsertType) => {
        const userWithMenus = {
            ...val,
            menu_id: selectedMenus
        };

        onSave(userWithMenus);
        reset();
        setSelectedMenus([]);
        onClose();
    };

    const handleClose = () => {
        setSelectedMenus([]);
        reset();
        onClose();
    };

    const handleMenuCheckboxChange = (menuId: number, parentId: string) => {
        setSelectedMenus((prevSelectedMenus) => {
            let newSelectedMenus = [...prevSelectedMenus];
            const childMenus = allMenusData?.data?.data.menu.filter(menu => Number(menu.parent_id) === menuId).map(menu => Number(menu.menu_id));

            if (prevSelectedMenus.includes(menuId)) {
                newSelectedMenus = prevSelectedMenus.filter((id) => id !== menuId);
                newSelectedMenus = newSelectedMenus.filter(id => !childMenus.includes(id));
            } else {
                newSelectedMenus.push(menuId);
                if (childMenus.length > 0) {
                    newSelectedMenus = [...newSelectedMenus, ...childMenus];
                }
                if (parentId !== "0" && !newSelectedMenus.includes(Number(parentId))) {
                    newSelectedMenus.push(Number(parentId));
                }
            }
            return newSelectedMenus;
        });
    };

    const handleChildMenuCheckboxChange = (childMenuId: number, parentId: string) => {
        setSelectedMenus((prevSelectedMenus) => {
            let newSelectedMenus = [...prevSelectedMenus];

            if (prevSelectedMenus.includes(childMenuId)) {
                newSelectedMenus = prevSelectedMenus.filter((id) => id !== childMenuId);
            } else {
                newSelectedMenus.push(childMenuId);
            }

            const childMenus = allMenusData?.data?.data.menu.filter(menu => Number(menu.parent_id) === Number(parentId)).map(menu => Number(menu.menu_id));

            const noChildSelected = childMenus.every((id) => !newSelectedMenus.includes(id));
            if (noChildSelected) {
                newSelectedMenus = newSelectedMenus.filter((id) => id !== Number(parentId));
            } else if (!newSelectedMenus.includes(Number(parentId))) {
                newSelectedMenus.push(Number(parentId));
            }

            return newSelectedMenus;
        });
    };

    const mainMenus = allMenusData?.data?.data.menu.filter(menu => menu.parent_id === "0") || [];
    const getChildMenus = (parentId: string) => allMenusData?.data?.data.menu.filter(menu => menu.parent_id === parentId) || [];

    const getIconMenu = (iconName: string) => {
        const Icon = IconsTb[iconName] || IconsGi[iconName];

        return Icon ? <Icon className="inline-block mr-2 text-lg" /> : null;
    }

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <ModalHeader>Tambah Akses User</ModalHeader>
            <ModalBody>
                <form id="userForm" onSubmit={handleSubmit(handleAddNewUser)}>
                    <div className="mb-4">
                        <Label htmlFor="userType">Tipe User</Label>
                        <Select
                            id="user_type"
                            defaultValue=""
                            required
                            color={Boolean(errors?.user_type) ? "failure" : "gray"}
                            helperText={errors?.user_type?.message}
                            {...register('user_type', {
                                required: {
                                    value: true,
                                    message: 'Wajib diisi',
                                },
                            })}
                        >
                            <option value="">
                                Pilih tipe user
                            </option>
                            {OPTIONS_USER_TYPE.map((item) => (
                                <option key={item.key} value={item.key}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="mb-4">
                        <Label>Akses Menu</Label>
                        {isFetchingAllMenus ? (
                            <CircularProgress className="h-16 w-16 mb-4" />
                        ) : (
                            <div className="grid grid-cols-1 gap-2 mt-2">
                                {mainMenus.map((mainMenu) => (
                                    <div key={mainMenu.menu_id}>
                                        <div className="bg-gray-200 p-1">
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    {getIconMenu(mainMenu.icon_name)}
                                                    <Label htmlFor={`menu-${mainMenu.menu_id}`} className="text-sm font-medium text-gray-700">
                                                        {mainMenu.title}
                                                    </Label>
                                                </div>
                                                <input
                                                    type="checkbox"
                                                    id={`menu-${mainMenu.menu_id}`}
                                                    value={mainMenu.menu_id}
                                                    onChange={() => handleMenuCheckboxChange(Number(mainMenu.menu_id), mainMenu.parent_id)}
                                                    checked={selectedMenus.includes(Number(mainMenu.menu_id))}
                                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                />
                                            </div>
                                        </div>
                                        {(mainMenu.has_child) === false && (
                                            <div className="flex items-center justify-between p-1">
                                                <Label htmlFor={`menu-${mainMenu.menu_id}`} className="text-sm font-medium text-gray-700">
                                                    {mainMenu.title}
                                                </Label>
                                                <input
                                                    type="checkbox"
                                                    id={`menu-${mainMenu.menu_id}`}
                                                    value={mainMenu.menu_id}
                                                    onChange={() => handleMenuCheckboxChange(Number(mainMenu.menu_id), mainMenu.parent_id)}
                                                    checked={selectedMenus.includes(Number(mainMenu.menu_id))}
                                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                />
                                            </div>
                                        )}

                                        {/* Display child menus */}
                                        {getChildMenus(mainMenu.menu_id).map((childMenu) => (
                                            <div key={childMenu.menu_id} className="flex items-center p-1 justify-between">
                                                <Label htmlFor={`menu-${childMenu.menu_id}`} className="text-sm font-medium text-gray-700">
                                                    {childMenu.title}
                                                </Label>
                                                <input
                                                    type="checkbox"
                                                    id={`menu-${childMenu.menu_id}`}
                                                    value={childMenu.menu_id}
                                                    onChange={() => handleChildMenuCheckboxChange(Number(childMenu.menu_id), childMenu.parent_id)}
                                                    checked={selectedMenus.includes(Number(childMenu.menu_id))}
                                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                />

                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button variant="outlined" color="red" onClick={handleClose}>Batal</Button>
                <Button form="userForm" type="submit">Simpan</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddAccessRoleUser;
