import { useClinicDetail, useClinicUpdate } from "api/clinic";
import { useTeritory } from "api/teritory";
import { useUploadFiles } from "api/upload";
import FormSection from "components/FormSection";
import PageLoader from "components/PageLoader";
import useToaster from "context/ToasterContext";
import { UserData } from "entities/model/user";
import { useEffect, useMemo, useState } from "react";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Controller, useForm } from "react-hook-form";
import { ClinicDetailResponse } from "types/clinic-detail";
import { ClinicLogoAttachmentType } from "types/clinic-logo-attachment-type";
import { v4 as uuid } from "uuid";
import CardSatusehatStatus from "./CardSatusehatStatus";
import AttachmentLogoClinic from "./clinic/AttachmentLogoClinic";
import { Button, CircularProgress, MenuItem, TextField, Typography } from "@mui/material";

type ClinicType = Partial<ClinicDetailResponse["data"]["clinic"]>;

const FormUserClinic = () => {
  const auth = useAuthUser<UserData>();
  const toaster = useToaster();

  const clinic_id = auth?.clinicId;
  const { data } = useClinicDetail({ clinic_id });
  const editClinic = useClinicUpdate();
  const clinicData = data?.data?.data?.clinic;

  const uploadFiles = useUploadFiles();

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    resetField,
    control
  } = useForm<ClinicType>();

  const [attLogo, setAttLogo] = useState<ClinicLogoAttachmentType>(null);

  const watchProvince = watch("province_code");
  const watchCity = watch("city_code");
  const watchDistrict = watch("district_code");
  const watchVillage = watch("village_code");
  const watchZipCode = watch("zip_code");

  const { data: teritoryData } = useTeritory({
    provinceCode: watchProvince,
    cityCode: watchCity,
    districtCode: watchDistrict,
  });

  const provinceOptions = useMemo(() => {
    return teritoryData.province.map((item) => ({
      key: item.province_code,
      label: item.province_name,
    }));
  }, [teritoryData.province]);

  const cityOptions = useMemo(() => {
    return teritoryData.city.map((item) => ({
      key: item.city_code,
      label: item.city_name,
    }));
  }, [teritoryData.city]);

  const districtOptions = useMemo(() => {
    return teritoryData.district.map((item) => ({
      key: item.district_code,
      label: item.district_name,
    }));
  }, [teritoryData.district]);

  const villageOptions = useMemo(() => {
    return teritoryData.village.map((item) => ({
      key: item.village_code,
      label: item.village_name,
      zip_code: item.zip_code,
    }));
  }, [teritoryData.village]);

  useEffect(() => {
    if (watchVillage) {
      setValue(
        "zip_code",
        villageOptions.find((i) => i.key === watchVillage)?.zip_code
      );
    }
  }, [setValue, villageOptions, watchVillage]);

  useEffect(() => {
    if (clinicData) {
      setValue("address", clinicData.address);
      setValue("operational_hour", clinicData.operational_hour);
      setValue("clinic_name", clinicData.clinic_name);
      setValue("phone_number", clinicData.phone_number);
      setValue("province_code", clinicData.province_code);
      setValue("city_code", clinicData.city_code);
      setValue("district_code", clinicData.district_code);
      setValue("village_code", clinicData.village_code);
      setValue("clinic_code", clinicData.clinic_code);
      setValue("license_number", clinicData.license_number);
      if (clinicData.image_url) {
        setAttLogo({
          id: uuid(),
          file: undefined,
          url: clinicData.image_url
        })
      }

    }
  }, [setValue, clinicData]);

  const handleUploadFiles = (item: ClinicLogoAttachmentType): Promise<ClinicLogoAttachmentType> => {
    return new Promise((resolve, reject) => {
      if (item.url) {
        resolve(item);
        return;
      }

      uploadFiles.mutateAsync([item.file])
        .then(res => {
          if (res.data?.data?.upload) {
            const updatedItem = {
              ...item,
              file: undefined,
              url: res.data.data.upload[0]
            };
            resolve(updatedItem);
          } else {
            reject(res.data.message || "Gagal mengupload file");
          }
        });
    });
  };

  const submitForm = async (val: ClinicType) => {
    try {
      let uploadedPhoto = null;
      if (attLogo) {
        uploadedPhoto = await handleUploadFiles(attLogo);
      }

      const updatedVal = {
        ...val,
        image_url: attLogo ? uploadedPhoto?.url || clinicData?.image_url : ""
      };

      if (!editClinic.isLoading) {
        editClinic
          .mutateAsync({ clinic_id, ...updatedVal })
          .then((response) => {
            const success = response?.status === 200;
            toaster.open({
              title: success ? "Berhasil" : "Gagal",
              message: success
                ? "Berhasil menyimpan perubahan"
                : "Gagal menyimpan perubahan. Silahkan ulangi lagi",
              variant: success ? "success" : "error",
              autoClose: true,
            });
          })
          .catch((err) => {
            toaster.open({
              title: "Gagal",
              message: err,
              variant: "error",
              autoClose: true,
            });
          });
      }
    } catch (error) {
      console.error("Submit error", error);
      toaster.open({
        title: "Gagal",
        message: error.message || "An error occurred",
        variant: "error",
        autoClose: true,
      });
    }
  };

  return (
    <>
      {editClinic.isLoading && (
        <PageLoader />
      )}
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <FormSection title="Klinik" className="pt-2">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="space-y-6">
              <div>
                <AttachmentLogoClinic
                  title="Logo Klinik"
                  value={attLogo}
                  onChange={setAttLogo}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label="Kode Reg Fasyankes"
                  type="text"
                  placeholder="12345"
                  error={Boolean(errors?.clinic_code)}
                  helperText={errors?.clinic_code?.message}
                  {...register("clinic_code")}
                />
              </div>

              <div>
                <TextField
                  required
                  fullWidth
                  label="Nama Klinik"
                  type="text"
                  placeholder="Klinik Mitra Keluarga"
                  error={Boolean(errors?.clinic_name)}
                  helperText={errors?.clinic_name?.message}
                  {...register("clinic_name", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div>
                <TextField
                  required
                  label="Nomor Izin Klinik"
                  fullWidth
                  type="text"
                  error={Boolean(errors?.clinic_name)}
                  helperText="Untuk praktik mandiri diisi SIP"
                  {...register("license_number", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  label="Alamat Lengkap"
                  rows={2}
                  multiline
                  required
                  placeholder="Alamat lengkap sesuai kartu identitas"
                  error={Boolean(errors?.address)}
                  helperText={errors?.address?.message}
                  {...register("address", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div>
                <Controller
                  name="province_code"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      required
                      defaultValue={watchProvince}
                      label="Provinsi"
                      error={Boolean(errors?.province_code)}
                      helperText={errors?.province_code?.message}
                      value={field.value ?? ""}
                      onChange={(e) => {
                        resetField("city_code");
                        resetField("district_code");
                        resetField("village_code");
                        setValue("province_code", e.target.value);
                      }}
                    >
                      {provinceOptions?.map((item) => (
                        <MenuItem key={item.key} value={String(item.key)}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </div>

              <div>
                <Controller
                  name="city_code"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      required
                      defaultValue={watchCity}
                      label="Kota / Kabupaten"
                      error={Boolean(errors?.city_code)}
                      helperText={errors?.city_code?.message}
                      value={field.value ?? ""}
                    >
                      {cityOptions?.map((item) => (
                        <MenuItem key={item.key} value={String(item.key)}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </div>

              <div>
                <Controller
                  name="district_code"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      required
                      defaultValue={watchDistrict}
                      label="Kecamatan"
                      error={Boolean(errors?.district_code)}
                      helperText={errors?.district_code?.message}
                      value={field.value ?? ""}
                    >
                      {districtOptions?.map((item) => (
                        <MenuItem key={item.key} value={String(item.key)}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </div>

              <div>
                <Controller
                  name="village_code"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      required
                      defaultValue={watchVillage}
                      label="Kelurahan / Desa"
                      error={Boolean(errors?.village_code)}
                      helperText={errors?.village_code?.message}
                      value={field.value ?? ""}
                    >
                      {villageOptions?.map((item) => (
                        <MenuItem key={item.key} value={String(item.key)}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </div>

              <div>
                <TextField
                  InputLabelProps={{ shrink: !!watchZipCode }}
                  fullWidth
                  label="Kode Pos"
                  type="text"
                  placeholder="12345"
                  error={Boolean(errors?.zip_code)}
                  helperText={errors?.zip_code?.message}
                  {...register("zip_code")}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  required
                  label="Jam Operasional Klinik"
                  type="text"
                  placeholder="Buka tiap hari jam 18.00 - 21.00"
                  error={Boolean(errors?.operational_hour)}
                  helperText={errors?.operational_hour?.message}
                  {...register("operational_hour", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  label="Nomor Telepon"
                  required
                  type="text"
                  placeholder="(021) XXX-XXX"
                  error={Boolean(errors?.phone_number)}
                  helperText={errors?.phone_number?.message}
                  {...register("phone_number", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div className="flex justify-end">
                <Button variant="contained" type="submit">
                  {editClinic.isLoading ? (
                    <CircularProgress className="mx-auto block" />
                  ) : (
                    "Simpan"
                  )}
                </Button>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <Typography variant="subtitle2">Masa Aktif Klinik</Typography>
                <Typography variant="h6" className="!font-bold text-lg text-green-500">{clinicData?.active_until}</Typography>
              </div>
              <CardSatusehatStatus status={Number(clinicData?.satusehat_status) === 1 ? 'active' : 'inactive'} />

              <div className="bg-gray-100 p-4 rounded-md">
                <div className="space-y-6">
                  <div>
                    <Typography className="!font-bold text-md mb-2">
                      Bagaimana agar terhubung ke SATUSEHAT?
                    </Typography>
                    <ol className="list-decimal list-inside space-y-1" style={{}}>
                      <li>Daftarkan fasyankes Anda ke platform Mitramedis dengan masukkan <b>Kode Reg Fasyankes</b> ke kolom di sebelah kiri, lalu klik <b>Simpan</b></li>
                      <li>Tim kami akan segera melakukan pengecekan dan menghubungkan fasyankes Anda ke platform <b>SATUSEHAT</b></li>
                      <li>Lakukan pengecekan secara berkala untuk mengetahui update status pendaftaran fasyankes Anda</li>
                    </ol>
                  </div>

                  <div>
                    <Typography className="!font-bold text-md mb-2">
                      Bagaimana cara mengetahui kode fasyankes saya?
                    </Typography>
                    <ol className="list-decimal list-inside space-y-1">
                      <li>Login & Cek Kode Reg Fasyankes Anda <a className=' text-sky-500 font-bold' href='https://registrasifasyankes.kemkes.go.id/' target='_blank' rel='noopener noreferrer'>di sini</a></li>
                      <li>Jika kode faskes (Kode Reg Fasyankes) Anda tidak ditemukan atau Anda belum mempunyai kode faskes, Anda WAJIB mendaftarkan Fasyankes Anda <a className=' text-sky-500 font-bold' href='https://registrasifasyankes.kemkes.go.id/register/' target="_blank" rel='noopener noreferrer'>di sini</a></li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormSection>
      </form >
    </>
  );
};

export default FormUserClinic;
