import { Button, Chip, IconButton, Typography } from "@mui/material";
import { REGEX_NIK_VALIDATION } from "constants/patient";
import { HiChevronRight, HiOutlinePencil } from "react-icons/hi";
import { PatientTypeData } from "types/patient";

interface SearchPasienItemDisplayProps {
  item: PatientTypeData;
  onClick: () => void;
  onEditClick: () => void;
}

const SearchPasienItemDisplay = ({
  item,
  onClick,
  onEditClick,
}: SearchPasienItemDisplayProps) => {
  const hasInvalidNik = !REGEX_NIK_VALIDATION.test(item.id_card_number);

  return (
    <div
      className="flex cursor-pointer items-center justify-between border-b px-4 py-2 last:border-none hover:bg-gray-100"
      onClick={onClick}
    >
      <div className="space-y-0">
        <div className="flex items-center gap-2">
          <Typography variant="body1" className="!font-bold">
            {item.patient_name}
          </Typography>
          {hasInvalidNik && (
            <Chip label="NIK tidak valid" size="small" color="warning" />
          )}
        </div>
        <Typography variant="caption" color="GrayText">
          {item.medical_record_number} | {item.id_card_number}
        </Typography>
      </div>
      <div
        className="flex items-center gap-1"
        onClick={e => e.stopPropagation()}
      >
        {hasInvalidNik ? (
          <Button
            type="button"
            size="small"
            variant="contained"
            onClick={onEditClick}
            startIcon={<HiOutlinePencil size={12} />}
          >
            Ubah
          </Button>
        ) : (
          <IconButton size="small" onClick={onEditClick}>
            <HiOutlinePencil size={16} />
          </IconButton>
        )}
        <IconButton
          size="small"
          onClick={onClick}
        >
          <HiChevronRight size={16} />
        </IconButton>
      </div>
    </div>
  );
};

export default SearchPasienItemDisplay;
