// import { Button, Typography } from "@mui/material";
import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import { FaCheck, FaExclamation, FaInfo, FaTimes } from "react-icons/fa";

export interface ModalConfirmationProps {
  open: boolean;
  type?: "success" | "error" | "warning" | "info";
  title?: string;
  message?: string;
  primaryAction?: string;
  secondaryAction?: string;
  onPrimaryActionClick?: () => void;
  onSecondaryActionClick?: () => void;
  onClose: () => void;
}

const mapBgIcon = {
  success: "bg-light-green-500",
  error: "bg-red-500",
  warning: "bg-yellow-300",
  info: "bg-sky-500",
};

const mapIcon = {
  success: <FaCheck className="mx-auto h-10 w-10 text-white" />,
  error: <FaTimes className="mx-auto  h-10 w-10 text-white" />,
  warning: <FaExclamation className="mx-auto  h-10 w-10 text-white" />,
  info: <FaInfo className="mx-auto  h-10 w-10 text-white" />,
};

const ModalConfirmation = ({
  message,
  open,
  title,
  type = "info",
  primaryAction,
  secondaryAction,
  onPrimaryActionClick,
  onSecondaryActionClick,
  onClose,
}: ModalConfirmationProps) => {
  return (
    <Modal open={open} onClose={onClose} showCloseButton={false}>
      <ModalBody className="flex flex-col items-center justify-center pt-6">
        <div className="space-y-4 text-center">
          <div className="relative inline-block">
            <div
              className={clsx(
                "absolute bottom-0 left-0 right-0 top-0 scale-125 rounded-full p-1 opacity-50",
                mapBgIcon[type]
              )}
            />
            <div className={clsx(mapBgIcon[type], "relative rounded-full p-4")}>
              {mapIcon[type]}
            </div>
          </div>
          {Boolean(title) && (
            <Typography variant="h5">{title}</Typography>
          )}
          {Boolean(message) && (
            <div
              className="text-gray-700"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
      </ModalBody>
      {Boolean(primaryAction || secondaryAction) && (
        <ModalFooter>
          {Boolean(secondaryAction) && (
            <Button
              fullWidth
              variant="outlined"
              onClick={onSecondaryActionClick}
            >
              {secondaryAction}
            </Button>
          )}
          {Boolean(primaryAction) && (
            <Button
              fullWidth
              variant="contained"
              onClick={onPrimaryActionClick}
            >
              {primaryAction}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ModalConfirmation;
