import { useEffect, useState } from "react";
import SidebarOverlay from "./SidebarOverlay";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Container from "./Container";
import Main from "./Main";
import { ErrorBoundary } from "react-error-boundary";
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import EmptyData from "components/EmptyData";
import { useLocation, useNavigate } from "react-router-dom";
import useAppContext from "context/AppContext";
import usePageTracking from "hooks/usePageTracking";

function MainLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetail, isMobile } = useAppContext();
  const from = location.pathname !== '/'
    ? `?${new URLSearchParams({ from: `${location.pathname}` }).toString()}`
    : '';

  const [isSidebarOpen, setSidebarOpen] = useState(!isMobile);
  const isActiveSubs = userDetail?.clinic?.status !== undefined ? Number(userDetail?.clinic?.status) === 1 : true;

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (!isActiveSubs && !location.pathname.includes('/subscription')) {
      navigate("/subscription");
    }
  }, [isActiveSubs, location, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  usePageTracking();

  return (
    <div className="flex h-screen overflow-y-hidden bg-white">
      <SidebarOverlay isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Container>
        <Header toggleSidebar={toggleSidebar} />
        <Main>
          <ErrorBoundary fallback={<EmptyData />}>
            <AuthOutlet fallbackPath={`/login${from}`} />
          </ErrorBoundary>
        </Main>
      </Container>
    </div>
  );
}

export default MainLayout;
