import { Typography } from "@mui/material";
import { HiCheckCircle } from "react-icons/hi";

interface CardSatusehatStatusProps extends React.HTMLAttributes<HTMLDivElement> {
  status: 'active' | 'inactive';
}

const SATUSEHAT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

const CardSatusehatStatus = ({ status, ...rest }: CardSatusehatStatusProps) => {
  return (
    <div className="bg-gray-100 p-4 rounded-md" {...rest}>
      <div className="flex gap-4 justify-between items-center w-full">
        <div>
          <Typography variant="body2" className="!font-bold !text-gray-600">
            Status SATUSEHAT
          </Typography>
          {status === SATUSEHAT_STATUS.ACTIVE ? (
            <div className="flex gap-2">
              <HiCheckCircle className="text-green-500" size={24} />
              <Typography variant="body1" className="!font-bold !text-green-500">Terhubung</Typography>
            </div>
          ) : (
            <Typography variant="body1" className="!font-bold !text-red-500">Belum Terhubung</Typography>
          )}
        </div>
        <img src="/logo_satusehat.webp" alt="Satu Sehat" width={32} height={32} />
      </div>
    </div>
  )
};

export default CardSatusehatStatus;