import { Label } from 'flowbite-react';
import { Button } from '@material-tailwind/react';
import Modal from 'components/Modal';
import ModalBody from 'components/ModalBody';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { useAllMenu } from 'api/menu';
import { useDetailAccessRole } from 'api/access-role';
import { OPTIONS_USER_TYPE } from 'constants/options';
import { CircularProgress, Typography } from '@mui/material';
import * as IconsTb from "react-icons/tb";
import * as IconsGi from "react-icons/gi";

interface ViewUserProps {
    isOpen: boolean;
    onClose: () => void;
    access_role_id: string;
}

const ModalViewAccessRoleUser = ({ isOpen, onClose, access_role_id }: ViewUserProps) => {
    const { data: allMenusData, isFetching: isFetchingAllMenus } = useAllMenu();
    const { data } = useDetailAccessRole({ access_role_id });

    const userType = data?.data?.data?.access_role?.user_type;
    const selectedMenus = data?.data?.data?.access_role?.menu_id;


    const mainMenus = allMenusData?.data?.data?.menu.filter(menu => menu.parent_id === "0") || [];
    const getChildMenus = (parentId: string) => allMenusData?.data?.data?.menu.filter(menu => menu.parent_id === parentId) || [];

    const getIconMenu = (iconName: string) => {
        const Icon = IconsTb[iconName] || IconsGi[iconName];

        return Icon ? <Icon className="inline-block mr-2 text-lg" /> : null;
    }

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalHeader>Detail Akses User</ModalHeader>
            <ModalBody>
                <div className="mb-4">
                    <Label>Tipe User</Label>
                    <Typography variant="subtitle2">
                        {OPTIONS_USER_TYPE.find((i) => i.key === Number(userType))?.label}
                    </Typography>
                </div>
                <div className="mb-4">
                    <Label>Akses Menu</Label>
                    {isFetchingAllMenus ? (
                        <CircularProgress className="h-16 w-16 mb-4 center" />
                    ) : (
                        <div className="grid grid-cols-1 gap-2 mt-2">
                            {mainMenus.map((mainMenu) => (
                                <div key={mainMenu.menu_id}>
                                    <div className="bg-gray-200 p-1">
                                        <div className="flex items-center justify-between">
                                            <div>
                                                {getIconMenu(mainMenu.icon_name)}
                                                <Label htmlFor={`menu-${mainMenu.menu_id}`} className="text-sm font-medium text-gray-700">
                                                    {mainMenu.title}
                                                </Label>
                                            </div>
                                            <input
                                                type="checkbox"
                                                id={`menu-${mainMenu.menu_id}`}
                                                value={mainMenu.menu_id}
                                                checked={selectedMenus?.includes(Number(mainMenu.menu_id))}
                                                readOnly
                                                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                            />
                                        </div>
                                    </div>
                                    {(mainMenu.has_child) === false && (
                                        <div className="flex items-center justify-between p-1">
                                            <Label htmlFor={`menu-${mainMenu.menu_id}`} className="text-sm font-medium text-gray-700">
                                                {mainMenu.title}
                                            </Label>
                                            <input
                                                type="checkbox"
                                                id={`menu-${mainMenu.menu_id}`}
                                                value={mainMenu.menu_id}
                                                checked={selectedMenus?.includes(Number(mainMenu.menu_id))}
                                                readOnly
                                                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                            />
                                        </div>
                                    )}

                                    {/* Display child menus */}
                                    {getChildMenus(mainMenu.menu_id).map((childMenu) => (
                                        <div key={childMenu.menu_id} className="flex items-center p-1 justify-between">
                                            <Label htmlFor={`menu-${childMenu.menu_id}`} className="text-sm font-medium text-gray-700">
                                                {childMenu.title}
                                            </Label>
                                            <input
                                                type="checkbox"
                                                id={`menu-${childMenu.menu_id}`}
                                                value={childMenu.menu_id}
                                                checked={selectedMenus?.includes(Number(childMenu.menu_id))}
                                                readOnly
                                                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                            />

                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="outlined" color="red" onClick={onClose}>Tutup</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalViewAccessRoleUser;
