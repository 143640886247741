export const USER_ROLE = {
    ADMIN: 1,
    ASSISTANT_DOCTOR: 2,
    DOCTOR: 3,
    PHARMACY: 6,
    CASHIER: 8
}

export const STOCK_CATEGORY = {
    DRUG: 1,
    BHP: 3,
    COSMETIC: 5,
    OTHER: 99
}

export const POLY_CLINIC = {
    GENERAL: 1,
    DENTAL: 2,
}

export const PRINT_TYPE = {
    A4: 'A4',
    THERMAL: 'THERMAL'
}

export const MENU_ID = {
    DASHBOARD: "1",
    PATIENT: "2",
    QUEUE: "3",
    MEDICAL_RECORD: "4",
    PHARMACY: "5",
    CASHIER: "6",
    LABORATORY: "7",
    STOCK: "8",
    STOCK_DATA: "9",
    STOCK_PURCHASE: "10",
    STOCK_OPNAME: "11",
    STOCK_SUPPLIER: "12",
    MEDICAL_TREATMENT: "13",
    DOCUMENT: "14",
    DOCUMENT_PATIENT: "15",
    DOCUMENT_MEDICAL_RESUME: "16",
    DOCUMENT_HEALTH_LETTER: "17",
    DOCUMENT_SICK_LETTER: "18",
    DOCUMENT_REFERAL: "19",
    DOCUMENT_INVOICE: "20",
    REPORT: "21",
    USER_MANAGEMENT: "22",
    SETTINGS: "23",
    SUBSCRIPTION: "24",
    HELP: "25",
}

export const REFERRAL_TYPE = {
    INTERNAL: '1',
    EXTERNAL: '2'
}

export const SPECIALIST_TYPE = {
    SPECIALIST: '1',
    SPECIFIC: '2'
}

export const REFERRAL = {
    VERTICAL_OUTPATIENT: '2',
    HORIZONTAL_OUTPATIENT: '3',
    APS_OUTPATIENT: '5',
    VERTICAL_INPATIENT: '9',
    HORIZONTAL_INPATIENT: '10',
    APS_INPATIENT: '12',
}

export const TYPE_OF_CARE = {
    INPATIENT: '1',
    OUTPATIENT: '0'
}

export const ALLERGY_TYPE = {
    FOOD: '01',
    AIR: '02',
    DRUG: '03'
}