import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useEffect, type ReactNode } from "react";
import { useLocation } from "react-router-dom";

interface Breadcrumb {
  text: string;
  url?: string;
  icon?: ReactNode;
}

interface PageHeadingProps {
  title: string;
  breadcrumbs?: Breadcrumb[];
  children?: ReactNode;
  subtitle?: string;
}

const PageHeading = ({
  title,
  subtitle,
  breadcrumbs,
  children,
}: PageHeadingProps) => {

  const location = useLocation();

  useEffect(() => {
    document.title = `Mitramedis | ${title || subtitle}`;
  }, [location, title, subtitle]);

  return (
    <div className="mb-4 flex flex-col items-start justify-between gap-2 md:flex-row md:items-end">
      <div className="shrink-0 space-y-2">
        <Breadcrumbs
          aria-label="Main breadcrumb"
        >
          {breadcrumbs?.map((item, index) => (
            <Link
              key={index}
              href={item.url}
            >
              {item.text}
            </Link>
          ))}
        </Breadcrumbs>
        <div className="min-w-0 flex-1">
          <Typography variant="h4">
            {title}
          </Typography>
          {subtitle && (
            <Typography
              className="sm:text-md text-sm leading-5 !text-gray-500 dark:text-gray-50 sm:tracking-tight"
            >
              {subtitle}
            </Typography>
          )}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default PageHeading;
