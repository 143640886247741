import { Alert, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useAllPatient } from "api/patient";
import EmptyData from "components/EmptyData";
import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { REGEX_NIK_VALIDATION } from "constants/patient";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PatientTypeData } from "types/patient";
import { useDebounce } from "use-debounce";
import SearchPasienItemDisplay from "./SearchPasienItemDisplay";

type Source = 'medical-record' | 'cashier' | 'queue';
interface PasienType {
  id_card_number: string;
  patient_name: string;
  medical_record_number: string;
}

interface SearchPasienProps {
  title?: string;
  open: boolean;
  source?: Source;
  onClose: () => void;
  onResultClick?: (id: PatientTypeData['patient_id']) => void;
}

const SearchPasien = ({ title, open, source, onClose, onResultClick }: SearchPasienProps) => {
  const navigate = useNavigate();

  const { register, watch } = useForm<PasienType>();

  const [keywordName] = useDebounce(watch("patient_name"), 500);
  const [keywordMr] = useDebounce(watch("medical_record_number"), 500);
  const [keywordNik] = useDebounce(watch("id_card_number"), 500);

  const hasSearchQuery = Boolean(
    keywordName?.length || keywordMr?.length || keywordNik?.length
  );

  const query = new URLSearchParams({
    id_card_number: keywordNik,
    medical_record_number: keywordMr,
    patient_name: keywordName,
  }).toString();

  const { data, isFetching } = useAllPatient(query, { enabled: hasSearchQuery });
  const hasInvalidNik = data?.data?.data?.patient?.some(
    (item: PatientTypeData) => {
      return !REGEX_NIK_VALIDATION.test(item.id_card_number);
    }
  )

  const handlePasienClick = (item: PatientTypeData) => {
    if (onResultClick) {
      onResultClick(item.patient_id);
    } else {
      navigate(`/medical-record/new/${item.patient_id}`);
    }

    onClose();
  };

  const handleNewPasienClick = () => {
    navigate("/patient/new");
    onClose();
  }

  const handleEditClick = (item: PatientTypeData) => {
    const searchParams = new URLSearchParams({ source }).toString();
    navigate(`/patient/edit/${item.patient_id}?${searchParams}`);
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>{title || "Cari Pasien"}</ModalHeader>
      <ModalBody>
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-2">
            <TextField
              type="search"
              placeholder="Nama Pasien"
              className="col-span-2"
              autoComplete="off"
              {...register("patient_name")}
            />
            <TextField
              type="search"
              placeholder="No. Rekam Medis"
              className="col-span-1"
              autoComplete="off"
              {...register("medical_record_number")}
            />
            <TextField
              type="search"
              placeholder="NIK"
              className="col-span-1"
              autoComplete="off"
              {...register("id_card_number")}
            />
          </div>

          <div className="space-y-2">
            <Typography variant="subtitle1" className="!font-bold">
              Hasil Pencarian
            </Typography>
            <div className="max-h-[260px] overflow-y-auto rounded-lg border">
              {isFetching && (
                <div className="flex items-center justify-center p-4">
                  <CircularProgress />
                </div>
              )}
              {!hasSearchQuery && <EmptyData>Ketik di kolom pencarian</EmptyData>}
              {hasSearchQuery &&
                !isFetching &&
                data?.data?.data?.patient?.length === 0 && (
                  <EmptyData>Data tidak ditemukan</EmptyData>
                )}
              {hasSearchQuery && !isFetching &&
                data?.data?.data?.patient?.map((item, index) => (
                  <SearchPasienItemDisplay
                    key={index}
                    item={item}
                    onClick={() => handlePasienClick(item)}
                    onEditClick={() => handleEditClick(item)}
                  />
                ))}
            </div>
          </div>
          {hasInvalidNik && (
            <Alert
              severity="warning"
              className="w-full"
            >
              NIK diperlukan untuk bisa terkoneksi ke layanan <strong className="text-green-500 font-extrabold">SATUSEHAT</strong>. Silakan sesuaikan NIK pasien dengan menekan tombol "Ubah" apabila pasien yang dipilih berlabel "NIK tidak valid".
            </Alert>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Alert
          severity="info"
          className="w-full"
          action={
            <Button
              size="small"
              type="button"
              variant="outlined"
              color="secondary"
              onClick={handleNewPasienClick}
            >
              Input Pasien Baru
            </Button>
          }
        >
          Tidak dapat menemukan pasien?
        </Alert>
      </ModalFooter>
    </Modal>
  );
};

export default SearchPasien;
