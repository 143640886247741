import { colors } from '@mui/material';
import { ThemeOptions, createTheme } from '@mui/material/styles';


export const theme: ThemeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0198dc',
    },
    secondary: {
      main: '#053361',
    },
  },
  spacing: [0, 2, 4, 8, 16, 32, 64],
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: 600,
      letterSpacing: -0.75,
    },
    h2: {
      fontWeight: 600,
      letterSpacing: -0.75,
    },
    h3: {
      fontWeight: 600,
      letterSpacing: -0.75,
    },
    h4: {
      fontWeight: 600,
      letterSpacing: -0.75,
    },
    h5: {
      fontWeight: 600,
      letterSpacing: -0.75,
    },
    h6: {
      fontWeight: 600,
      letterSpacing: -0.75,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          letterSpacing: 0,
          textTransform: 'none',
          borderRadius: 6,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          letterSpacing: '0.1em',
          fontSize: '0.80rem',
          '& ol li a': {
            cursor: 'pointer',
            color: '#4b5563',
          },
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          borderBottom: '2px solid #e0e0e0',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '32px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#053361',
          fontWeight: 700,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
      variants: [
        {
          props: { variant: 'elevation' },
          style: {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          }
        }
      ]
    },
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#f9fafb',
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '.MuiInputLabel-asterisk': {
            color: colors.red[500],
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '.MuiTypography-root': {
            fontWeight: '600',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
          borderBottom: '1px solid #e0e0e0',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          padding: '12px'
        }
      }
    }
  },
});

export default createTheme(theme);