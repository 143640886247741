import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";
import { SatusehatConfigUpdateResponse } from "types/clinic-satusehat-config-update";

interface Payload {
  clinic_id: string,
  client_id: string
  client_secret: string
  organization_id: string
}

const useSatusehatConfigUpdate = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useMutation<AxiosResponse<SatusehatConfigUpdateResponse>, unknown, Payload, unknown>({
    mutationKey: ['update-satusehat-config'],
    mutationFn: async (payload: Payload) => {
      const { clinic_id, ...restPayload } = payload;

      try {
        return await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/satusehat/config/${clinic_id}`, JSON.stringify(restPayload), { headers });
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
  });
}

export default useSatusehatConfigUpdate;