import axios from 'axios';
import { UserData } from 'entities/model/user';
import createRefresh from 'react-auth-kit/createRefresh';

const refreshToken = createRefresh({
  interval: 30,
  refreshApiCallback: async ({ authToken, refreshToken, authUserState }: { authToken?: string; refreshToken?: string; authUserState: unknown }) => {
    try {
      if (process.env.NODE_ENV !== 'production') {
        console.log("Refreshing Token...");
      }

      const auth = authUserState as UserData;

      const userId = auth?.userId;
      const clinicId = auth?.clinicId;
      const clinicType = auth?.clinicType;
    
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
        'User-ID': userId,
        'Clinic-ID': clinicId,
        'Clinic-Type': clinicType
      }

      const payload = {
        refresh_token: refreshToken,
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/refresh-token`, JSON.stringify(payload), { headers });

      if (!data?.success) {
        throw new Error(data?.message || "Failed to refresh token");
      }

      const tokenData = data?.data;

      return {
        isSuccess: !!tokenData,
        newAuthToken: tokenData?.access_token,
        newAuthTokenExpireIn: tokenData?.access_token_expired_at,
      }
    } catch (error) {
      console.error(error)
    }
  }
})

export default refreshToken;