import { Button, CircularProgress, TextField } from "@mui/material";
import { useSatusehatConfig, useSatusehatConfigUpdate } from "api/clinic";
import FormSection from "components/FormSection";
import useToaster from "context/ToasterContext";
import { UserData } from "entities/model/user";
import { useEffect, useState } from "react";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useForm } from "react-hook-form";
import CardSatusehatStatus from "./CardSatusehatStatus";

type ConfigType = {
  client_id: string;
  client_secret: string;
  organization_id: string;
}

const FormSatusehat = () => {
  const auth = useAuthUser<UserData>();
  const toaster = useToaster();

  const clinic_id = auth?.clinicId;
  const getConfig = useSatusehatConfig();
  const updateConfig = useSatusehatConfigUpdate();

  const [isIntegrated, setIsIntegrated] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<ConfigType>();

  useEffect(() => {
    if (clinic_id && !isDirty && !getConfig.isLoading) {
      getConfig.mutateAsync(clinic_id)
        .then((response) => {
          if (response?.status === 200) {
            const { data, message } = response.data;

            if (data) {
              const { satusehat_config: config } = data;

              setValue("client_id", config.client_id);
              setValue("client_secret", config.client_secret);
              setValue("organization_id", config.organization_id);

              setIsIntegrated(config.satusehat_status === "1");
            } else {
              throw new Error(message || "Data tidak ditemukan");
            }
          } else {
            throw new Error("Gagal mengambil data");
          }
        })
        .catch((err) => {
          toaster.open({
            title: "Gagal",
            message: err?.message,
            variant: "error",
            autoClose: true,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic_id]);

  const submitForm = async (val: ConfigType) => {
    if (clinic_id) {
      updateConfig.mutateAsync({
        clinic_id,
        ...val
      }).then((response) => {
        if (response?.status === 200) {
          const { success, message } = response.data;

          toaster.open({
            title: success ? "Berhasil" : "Gagal",
            message: message,
            variant: success ? "success" : "error",
            autoClose: true,
          });
        } else {
          throw new Error("Failed to save data");
        }
      }).catch((err) => {
        toaster.open({
          title: "Gagal",
          message: err,
          variant: "error",
          autoClose: true,
        });
      });
    }
  };

  return (
    <div className="max-w-lg">
      {getConfig.isLoading ? (
        <div className="flex justify-center items-center h-32">
          <CircularProgress />
        </div>
      ) : (
        <form noValidate onSubmit={handleSubmit(submitForm)}>
          <FormSection title="Konfirgurasi SATUSEHAT" className="pt-2">
            <div className="space-y-6">
              <CardSatusehatStatus status={isIntegrated ? 'active' : 'inactive'} />

              <TextField
                required
                label="Client ID"
                variant="outlined"
                fullWidth
                {...register("client_id", { required: "Client ID wajib diisi" })}
                error={!!errors.client_id}
                helperText={errors.client_id?.message}
                InputLabelProps={{
                  shrink: !!watch('client_id')
                }}
              />
              <TextField
                required
                label="Client Secret"
                variant="outlined"
                fullWidth
                {...register("client_secret", { required: "Client Secret wajib diisi" })}
                error={!!errors.client_secret}
                helperText={errors.client_secret?.message}
                InputLabelProps={{
                  shrink: !!watch('client_secret')
                }}
              />
              <TextField
                required
                label="Organization ID"
                variant="outlined"
                fullWidth
                {...register("organization_id", { required: "Organization ID wajib diisi" })}
                error={!!errors.organization_id}
                helperText={errors.organization_id?.message}
                InputLabelProps={{
                  shrink: !!watch('organization_id')
                }}
              />
              <div className="flex justify-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={updateConfig.isLoading}
                >
                  {updateConfig.isLoading ? "Menyimpan..." : "Simpan"}
                </Button>
              </div>
            </div>
          </FormSection>
        </form>
      )}
    </div>
  );
};

export default FormSatusehat;
