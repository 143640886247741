import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";
import { SatusehatConfigResponse } from "types/clinic-satusehat-config";

const useSatusehatConfig = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useMutation<AxiosResponse<SatusehatConfigResponse>, unknown, unknown, unknown>({
    mutationKey: 'get-satusehat-config',
    mutationFn: async (clinic_id) => {
      try {
        if (clinic_id === undefined) {
          return null;
        }

        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/satusehat/config/${clinic_id}`, { headers });
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
  });
}

export default useSatusehatConfig;