import Modal from "components/Modal";
import ModalHeader from "components/ModalHeader";
import ModalBody from "components/ModalBody";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

interface ErrorLoginProps {
    open: boolean;
    onClose: () => void;
}

const ModalErrorLogin = ({ open, onClose }: ErrorLoginProps) => {
    const [getIPAddress, setIPAddress] = useState("");

    const getData = async () => {
        try {
            const res = await axios.get("https://api.ipify.org/?format=json");
            setIPAddress(res.data.ip);
        } catch (error) {
            console.error("Gagal mengambil alamat IP", error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleIPAddress = () => {
        const phoneNumber = "6282245545552";
        const message = `Halo, terjadi masalah pada jaringan dengan IP Address: ${getIPAddress}. Mohon bantuannya.`;
        const encodedMessage = encodeURIComponent(message);

        window.open(`https://wa.me/${phoneNumber}?text=${encodedMessage}`, "_blank");
    };

    return (
        <Modal open={open} onClose={onClose} className="rounded-lg shadow-lg">
            <ModalHeader>
                Koneksi Jaringan Bermasalah
            </ModalHeader>
            <ModalBody className="pb-6 pt-2 px-4">
                <div className="space-y-4">
                    <Typography variant="body1" className="text-gray-700">
                        Sepertinya terjadi masalah pada koneksi jaringan Anda. Ini mungkin disebabkan oleh pemblokiran IP atau masalah konfigurasi lainnya. Harap segera hubungi tim dukungan kami untuk membantu menyelesaikan masalah ini.
                    </Typography>
                    <Typography variant="body2" className="text-gray-500">
                        Alamat IP Anda: <strong>{getIPAddress}</strong>
                    </Typography>
                    <Button
                        className="w-full"
                        onClick={handleIPAddress}
                        variant="outlined"
                    >
                        Kirim Detail ke WhatsApp
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalErrorLogin;
