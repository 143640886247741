import { Card, Tab, Tabs } from '@mui/material';
import PageHeading from 'components/PageHeading';
import useAppContext from 'context/AppContext';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import FormBpjs from './components/FormBpjs';
import FormClinic from './components/FormClinic';
import FormSatusehat from './components/FormSatusehat';
import FormUser from './components/FormUser';

const TABS = [
  { key: 'user', title: 'Profil', content: <FormUser /> },
  { key: 'clinic', title: 'Klinik', content: <FormClinic /> },
  { key: 'satusehat', title: 'SATUSEHAT', content: <FormSatusehat /> },
  { key: 'pcare', title: 'BPJS (PCare)', content: <FormBpjs /> },
];

const Settings = () => {
  const navigate = useNavigate();
  const params = useParams();
  const activeTab = params.tab as 'user' | 'clinic' | 'satusehat' | 'pcare';

  const { userDetail } = useAppContext();

  const filteredTabs = userDetail?.is_admin === '0'
    ? TABS.filter(tab => tab.key === 'user')
    : TABS;

  if (!activeTab) {
    return <Navigate to={`/settings/${filteredTabs[0]?.key}`} />;
  }

  return (
    <>
      <PageHeading title="Pengaturan" breadcrumbs={[{ text: 'Pengaturan' }]} />
      <div className="space-y-4">
        <Tabs value={activeTab} onChange={(e, tabKey) => navigate(`/settings/${tabKey}`)}>
          {filteredTabs.map(tab => (
            <Tab label={tab.title} value={tab.key} key={tab.key} />
          ))}
        </Tabs>
        <Card variant='outlined' className="min-h-[500px] p-4">
          {TABS.find((t) => t.key === activeTab)?.content}
        </Card>
      </div>
    </>
  );
};

export default Settings;
